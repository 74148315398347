import { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Spin,
  Alert
} from "antd";
import {
  CheckOutlined,
  LockOutlined,
  QuestionOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import AdminApi from "../api/admin-api";
import ActivateAccount from "./activate-account";
import ForgotPassword from "./forgot-password";

const LoginContainer = styled.div`
  background: rgb(222, 222, 222);
  width: 100%;
  height: 100vh;
  position: absolute;
`;

const StyledLoginForm = styled(Form)`
  background: white;
  width: ${(props) => (!props.$isLoading ? "450px" : "56px")};
  margin-left: auto;
  margin-right: auto;
  padding: ${(props) => (!props.$isLoading ? "40px 30px 10px 30px" : "10px")};
  border: 1px solid #ccc;
  border-radius: ${(props) => (!props.$isLoading ? "5px" : "50%")};
  box-shadow: 0px 3px 5px rgba(11, 11, 11, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: width 120ms, height 120ms, border-radius 120ms;
`;

const CenterSpin = styled(Spin)`
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 8px;
`;

const LoginForm = (props) => {
  const [accountActivationVisible, setAccountActivationVisible] =
    useState(false);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [logoUrl, setLogoUrl] = useState();

  useEffect(()=> {
    AdminApi.getLogo().then(logo => {
      setLogoUrl(logo);
    });
  }, []);

  const onFinish = async (values) => {
    try {
      let res = await AdminApi.login(values.id, values.password);
      AdminApi.storeLoginToken(res);
      props.onLoginSucces?.();
    } catch (e) {
      message.error(e);
    }
  };

  const loginMenu = {
    items: [
      {
        key: "forgotPassword",
        label: "Forgot Password",
        icon: <QuestionOutlined />,
      },
      {
        key: "activateAccount",
        label: "Activate Account",
        icon: <CheckOutlined />,
      },
    ],
    onClick: ({ key }) => {
      switch (key) {
        case "forgotPassword":
          setForgotPasswordVisible(true);
          break;
        case "activateAccount":
          setAccountActivationVisible(true);
          break;
      }
    },
  };

  return (
    <LoginContainer>
      <ActivateAccount
        open={accountActivationVisible}
        onComplete={() => {
          setAccountActivationVisible(false);
        }}
      />
      <ForgotPassword
        open={forgotPasswordVisible}
        onComplete={() => {
          setForgotPasswordVisible(false);
        }}
      />
      <StyledLoginForm
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        $isLoading={props.isLoading}
      >
        {props.isLoading}
        {props.isLoading && <CenterSpin />}

        {logoUrl && <img src={logoUrl} style={{ width: "60%", marginLeft: "50%", transform: "translate(-50%, 0)", pointerEvents: "none", padding: "10px", borderRadius: "10px", marginBottom: "30px" }} />}

        {window.location.hash == "#logout" && <Alert
          message="Your session has expired. Please login again."
          type="warning"
          style={{ marginBottom: "30px" }}
          closable
        />}

        {!props.isLoading && (
          <>
            <Form.Item
              name="id"
              rules={[
                { required: true, message: "Please input your Username!" },
              ]}
            >
              <Input
                size={"large"}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input
                size={"large"}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                style={{
                  width: "80%",
                }}
                size={"large"}
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </Button>
              <Dropdown trigger={"click"} menu={loginMenu}>
                <Button
                  style={{
                    width: "18%",
                    marginLeft: "2%",
                  }}
                  size={"large"}
                >
                  <SettingOutlined />
                </Button>
              </Dropdown>
            </Form.Item>
          </>
        )}
      </StyledLoginForm>
    </LoginContainer>
  );
};

export default LoginForm;
