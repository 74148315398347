import React from "react";
import { Row, Col, Table, Button, Drawer, Popconfirm } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import CommonListBase from "../../base/common-list-base";
import PageTitle from "../../components/common/page-title";
import ListFilters from "../../components/common/list-filters";
import { ajaxRequester } from "../../services/ajax-requester";
import InventorySelectInventory from "../../components/inventories/inventory-select-inventory";
import PhotoGallery from "../../components/common/photo-gallery";
import { currencyFormatter } from "../../components/common/common-formatter";

class InventoriesTracking extends CommonListBase {
  state = {
    listReq: "tracking/list?employeeId=1&",
    data: [],
    pagination: {
      current: 1,
      pageSize: 20,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    selectedRowKeys: [],
    selectedRows: [],
    isDrawer: false,
    screen: "inventories-tracking",
  };

  // Setting Columns
  setColumns = () => {
    let columns = [
      {
        title: "Media",
        dataIndex: "media_type",
        render: (text) => <span style={{ whiteSpace: "nowrap" }}>{text}</span>,
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "City",
        dataIndex: "city",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Area",
        dataIndex: "area",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Size",
        dataIndex: "size",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "SQFT",
        dataIndex: "sqft",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Light",
        dataIndex: "light_type",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Photo",
        dataIndex: "images",
        render: (text, record) => (
          <Button
            className="ant-btn-reset"
            disabled={record?.ha_images?.length > 0 ? false : true}
            icon={<FileImageOutlined />}
            onClick={() => this.handlePhotos(record)}
          />
        ),
      },
      {
        title: "Card Rate",
        dataIndex: "card_rate",
        render: (text) => currencyFormatter(text),
      },
      {
        title: "Booking Status",
        dataIndex: "booking_status",
        render: (text) =>
          text === "A"
            ? "Available"
            : text === "B"
            ? "Blocked"
            : "Not Available",
      },
    ];

    const filterColumns = [
      { columnName: "media_type", displayName: "Media" },
      { columnName: "quantity", displayName: "Qty" },
      { columnName: "inventory_code", displayName: "IID" },
      { columnName: "city", displayName: "City" },
      { columnName: "area", displayName: "Area" },
      { columnName: "location", displayName: "Location", default: true },
      { columnName: "size", displayName: "Size" },
      { columnName: "sqft", displayName: "SQFT" },
      { columnName: "light_type", displayName: "Light" },
      { columnName: "images", displayName: "Photo" },
      { columnName: "card_rate", displayName: "Card Rate" },
      { columnName: "booking_status", displayName: "Booking Status" },
      { columnName: "campaign_start_date", displayName: "Campaign Start Date" },
      { columnName: "campaign_end_date", displayName: "Campaign End Date" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  // Add Sites
  addSites = (values) => {
    let ha_inventory_ids = values.map((x) => x.ha_inventory_id);

    this.setState({ isDrawer: false, loading: true });

    var reqUrl = "tracking/inventory/add";
    var reqObj = {
      request: {
        employee_id: 1,
        ha_inventory_id: ha_inventory_ids,
        created_by: "varun",
      },
    };
    ajaxRequester(reqUrl, reqObj, () => {
      const { pagination } = this.state;
      this.getResults({ pagination });
    });
  };

  // Handle Drawer
  handleDrawer = (record) => {
    this.setState({
      isDrawer: !this.state.isDrawer,
      haBaseMode: record,
    });
  };

  // Check Box Selection
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows });
  };

  // Delete Selected Inventories
  deleteMultiInventory = () => {
    this.setState({ loading: true });
    var reqUrl = "tracking/inventory/delete";
    var reqObj = {
      request: {
        employee_id: 1,
        ha_inventory_id: this.state.selectedRowKeys,
        created_by: "varun",
      },
    };
    ajaxRequester(reqUrl, reqObj, () => {
      this.setState({ selectedRowKeys: [] });
      const { pagination } = this.state;
      this.getResults({ pagination });
    });
  };

  // Handle Photos SlideShoe
  handlePhotos = (record) => {
    this.setState({
      isShowPhotos: !this.state.isShowPhotos,
      photos: record ? record?.ha_images : "",
      photosTitle: record ? record : "",
    });
  };

  componentDidMount() {
    // Get Inventories Tracking List
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const {
      data,
      columns,
      pagination,
      loading,
      selectedRowKeys,
      filterColumns,
      isDrawer,
      screen,
      isShowPhotos,
      photos,
      photosTitle,
    } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,

      getCheckboxProps: (record) => ({
        disabled: record.is_read,
        is_read: record.is_read,
      }),
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitle
              titleText="Track List"
              isAddBtn={"Add Sites"}
              handleAdd={this.handleDrawer}
            />

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <div
              style={{
                top: "-48px",
                float: "right",
                right: "48px",
                marginBottom: "-48px",
                position: "relative",
              }}
            >
              <Popconfirm
                title="Are you sure delete?"
                placement="left"
                okText="Yes"
                cancelText="No"
                onConfirm={() => this.deleteMultiInventory()}
              >
                <Button
                  type="primary"
                  disabled={!hasSelected}
                  loading={loading}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>

            <Table
              columns={columns}
              rowKey={(record) => record.ha_inventory_id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
              // expandable={{
              //   expandedRowRender: (record) => this.getMultiDetails(record),
              //   rowExpandable: (record) =>
              //     record.ha_base_inventories &&
              //     record.ha_base_inventories.length > 0,
              // }}
              rowSelection={rowSelection}
            />
          </Col>
        </Row>

        <Drawer
          title="Select Inventories"
          placement="right"
          open={isDrawer}
          onClose={this.handleDrawer}
          width={"88%"}
          key={isDrawer}
        >
          <InventorySelectInventory
            key={isDrawer}
            addSelectedInventory={this.addSites}
          />
        </Drawer>

        {isShowPhotos && (
          <PhotoGallery
            photos={photos}
            photosTitle={photosTitle}
            handlePhotos={this.handlePhotos}
          />
        )}
      </React.Fragment>
    );
  }
}

export default InventoriesTracking;
