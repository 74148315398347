import * as dayjs from "dayjs";

export const currencyFormatter = (currency) => {
  let culture_name = localStorage.getItem("culture_name") || "en-IN";

  if (culture_name !== "en-IN") {
    let x = Number(currency);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res =
      x.length > 3
        ? !x.includes("-")
          ? otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree
          : x.length > 4
          ? otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree
          : x
        : x;
    return res;
  } else {
    let x = Number(currency);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== "") lastThree = "," + lastThree;
    let res =
      x.length > 3
        ? !x.includes("-")
          ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
          : x.length > 4
          ? otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
          : x
        : x;
    return res;
  }
};

export const dateFormatter = (date) => {
  let res = dayjs(date).format("DDMMMYY");
  return res;
};

export const timeFormatter = (date) => {
  let res = dayjs(date).format("h:mm a");
  return res;
};

export const resDate = (date) => {
  let result = dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
  return result;
};

export const perFormatter = (val) => {
  let res = val + "%";
  return res;
};
